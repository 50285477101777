import React, { useEffect, useState } from 'react';
import { getPublications, Publication } from '../../../../../../api/MediaLinksApi';

type Props = {
    selectedPublications: Publication[];
    onChange: (id: Publication) => void;
    scheduleId?: string;
};

const Publications = ({
    selectedPublications, onChange, scheduleId,
}: Props): JSX.Element => {
    const [publications, setPublications] = useState<Publication[]>([]);

    useEffect(() => {
        if (scheduleId) {
            const fetchPublications = async () => {
                const fetchedPublications = await getPublications(scheduleId);
                setPublications(fetchedPublications);
            };
            fetchPublications();
        }
    }, [scheduleId]);

    return (
        <div className="form-group" data-testid="tree">
            <span className="col-md-12">Publications</span>
            <div className="col-md-12 tree-container publication-list">
                {publications.map(p => (
                    <label key={p.id}>
                        <input
                            type="checkbox"
                            className="tree-checkbox"
                            checked={selectedPublications.includes(p)}
                            onClick={() => onChange(p)}
                        />
                        {p.name}
                    </label>
                ))}
            </div>
        </div>
    );
};

export default Publications;
