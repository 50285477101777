import React, { useState } from 'react';
import {
    AssessmentQExercisePostRequestBody,
    postAssessmentQExercises,
    AssessmentQExercise,
    Publication,
    patchAssessmentQExercises,
} from '../../../../api/MediaLinksApi';
import Schedules from './components/Schedules/Schedules';
import Publications from './components/Publications/Publications';
import MedialinkKind from '../../utitilies/medialink-kind';

export type MediaLink = {
    name: string;
    id: string;
    createdAt: string;
    kind: typeof MedialinkKind.EXERCISE_ASSESSMENTQ;
    assessmentQExercise: AssessmentQExercise;
};

type Props = {
    onClose: () => void;
    medialinks: MediaLink[];
    onSave: () => void;
    moduleId: string;
    nodeId: string;
    medialink?: MediaLink;
};

const AssessmentQSettings = ({
    onClose, onSave, moduleId, nodeId, medialink, medialinks,
}: Props): JSX.Element => {
    const isEditing = Boolean(medialink?.id);

    const [error, setError] = useState<string | null>(null);
    const [selectedSchedule, setSelectedSchedule] = useState<{ value: string; label: string; } | null>(isEditing && medialink ? {
        value: medialink.assessmentQExercise.schedule.id,
        label: medialink.assessmentQExercise.schedule.name,
    } : null);
    const [selectedPublications, setSelectedPublications] = useState<Publication[]>([]);
    const [name, setName] = useState<string | undefined>(medialink?.name || undefined);
    const [showScore, setShowScore] = useState<boolean>(medialink?.assessmentQExercise?.showScore || false);

    const handleSubmit = async () => {
        try {
            if (isEditing && medialink && name) {
                await patchAssessmentQExercises(moduleId, nodeId, medialink.id, { name, showScore });
                onSave();
            }

            if ((selectedPublications.length === 0 || !selectedSchedule)) return;

            const exercises: AssessmentQExercisePostRequestBody = selectedPublications.map(publication => ({
                name: publication.name,
                kind: MedialinkKind.EXERCISE_ASSESSMENTQ,
                assessmentQExercise: {
                    showScore,
                    publication,
                    schedule: {
                        id: selectedSchedule.value,
                        name: selectedSchedule.label,
                    },
                },
            }));

            await postAssessmentQExercises(moduleId, nodeId, exercises);

            onSave();
        } catch (e) {
            setError('Something went wrong, no AssessmentQ medialinks have been created.');
        }
    };

    const handleScheduleChange = React.useCallback(selectedOption => {
        setSelectedPublications([]);
        setSelectedSchedule(selectedOption);
    }, []);

    return (
        <form noValidate className="form-horizontal assessment-q-settings">
            <div className="panel panel-default">
                <div className="panel-heading">
                    <h3 className="panel-title">Add/edit assessmentQ Publication(s)</h3>
                </div>
                <div className="panel-body">
                    <div className="col-sm-12">
                        {isEditing && (
                            <div className="form-group">
                                <label htmlFor="name" className="control-label col-md-4">Name</label>
                                <div className="col-md-7">
                                    <input
                                        id="name"
                                        name="name"
                                        className="form-control col-md-4"
                                        type="text"
                                        required
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                    />
                                </div>
                            </div>
                        )}
                        <Schedules
                            medialinks={medialinks}
                            disabled={isEditing}
                            selectedSchedule={selectedSchedule}
                            onChange={handleScheduleChange}
                        />
                        {!isEditing && (
                            <Publications
                                onChange={publication => setSelectedPublications(prev => {
                                    if (prev.includes(publication)) {
                                        return prev.filter(p => p.id !== publication.id);
                                    }
                                    return [...prev, publication];
                                })}
                                selectedPublications={selectedPublications}
                                scheduleId={selectedSchedule?.value}
                            />
                        )}
                        <div className="form-group">
                            <label htmlFor="showScore" className="control-label col-md-4">Show score</label>
                            <div className="col-md-7">
                                <input type="checkbox" id="showScore" onChange={e => setShowScore(e.target.checked)} checked={showScore} />
                            </div>
                        </div>
                        {error && <div className="alert alert-danger">{error}</div>}
                    </div>
                </div>
                <div className="panel-footer">
                    <div className="ngdialog-buttons">
                        <button onClick={onClose} type="button" className="btn ngdialog-button ngdialog-button-secondary">
                            Cancel
                        </button>
                        <button type="button" className="btn ngdialog-button ngdialog-button-primary" onClick={handleSubmit}>
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default AssessmentQSettings;
